import {Component, h, Host, Prop, State, Watch} from '@stencil/core';
import {CardsWithPos, CardsWithPosUtil, CarouselCard, FuzzyBoolean} from "../../../utils/utils";

@Component({
  tag: 'wizard-cards',
  styleUrl: 'wizard-cards.css',
  shadow: true,
})
export class WizardCards implements CardsWithPos {
  @Prop({mutable:true})
  cards: CarouselCard[] | string
  @Prop()
  textColor: string
  @State()
  currentCard = -1
  @Prop()
  disabledColor: string = "lightgray"
  @Prop()
  disabledButtonColor: string = null
  @Prop()
  size: number = 10  // Size in pixels
  @Prop()
  showButtonsAbove: boolean = true
  @Prop()
  roundButtons: boolean = true
  @Prop()
  buttonsColor: string

  @Watch("cards")
  onCardsChange(){
    if (this.cards && this.currentCard >= this.cards.length)
      this.currentCard = this.cards.length - 1
  }

  public static renderCards(obj: CardsWithPos) {
    return CardsWithPosUtil.renderCustom(obj, (card, pos) => <carousel-card
      style={{display: pos == obj.currentCard ? "block" : "none"}} html={card.html ? card.html : card.text}
      image-url={card.imageUrl}/>)
  }

  render() {
    return WizardCards.renderWizard(this)
  }

  public static renderWizard(obj: CardsWithPos) {
    CardsWithPosUtil.chooseCurrentCard(obj)

    return (
      <Host>
        <div style={{display: obj.currentCard >= 0 ? "block" : "none"}}>
          <div>{obj.showButtonsAbove ? obj.renderButtons() : WizardCards.renderCards(obj)}</div>
          <div>{obj.showButtonsAbove ? WizardCards.renderCards(obj) : obj.renderButtons()}</div>
        </div>
      </Host>
    );
  }

  renderButtons() {
    return CardsWithPosUtil.renderCustom(this, (card, pos) => {
      let onclick: () => void = () => {
        this.currentCard = pos
      }
      if (this.roundButtons)
        return <round-button class={ pos === this.currentCard ? "selectedButton" : null}
                             value={card.title} onClick={card.enabled === FuzzyBoolean.FALSE ? null : onclick} growOnHovering={true}/>
      return <flat-button style={{fontWeight: pos === this.currentCard ? "bold" : "normal"}}
                           marginRight={(this.size/2) + "px"} size={this.size} buttonColor={card.enabled === FuzzyBoolean.FALSE && this.disabledButtonColor ? this.disabledButtonColor : this.buttonsColor}
                           textColor={card.enabled === FuzzyBoolean.FALSE ? this.disabledColor : this.textColor}
                           text={card.title} onClick={card.enabled === FuzzyBoolean.FALSE ? null : onclick}/>
    })
  }
}
