:host {
  display: block;
}

flat-button {
  padding-top: 2px;
}

round-button {
  margin-right: 10px;
}
.selectedButton {
  font-weight: bold;
}
